<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();

const handleChallengeClick = () =>
	loginGuard({
		success: () => {
			open("LazyOModalChallengeSeason");

			dispatchGAEvent({
				event: "click_button",
				button_name: "winter_challenge_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_challenge" });
		}
	});
</script>
<template>
	<div class="event-item" @click="handleChallengeClick">
		<div class="event-wrap">
			<AText as="div" class="label" variant="ternopil" :modifiers="['uppercase', 'bold']">
				<span>{{ t("New") }}</span>
			</AText>
			<NuxtImg
				src="/nuxt-img/challenge/snow-ball-side.png"
				alt="challenge season"
				format="webp"
				width="49"
				height="50"
			/>
		</div>
		<AText :modifiers="['uppercase', 'bold']" gradient="var(--gadag)">
			<span>{{ t("winter Challenge") }}</span>
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.event-wrap {
	min-width: 64px;
	height: 50px;
	position: relative;

	.label {
		position: absolute;
		left: 0;
		top: 0;
		color: var(--coro);
		background-color: var(--caserta);
		text-shadow: 0px 1px 1px var(--coronel);
		display: inline-block;
		border-radius: 5px;
		padding: gutter(0.25) gutter(0.5);
		line-height: 12px;
	}
}
</style>
